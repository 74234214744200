import React from 'react';
import './AdminContact.css'; // External CSS file for styling
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome
import Whaatsapp from '../WhatsApp.svg';
import CallIcon from '../25489.jpg';
import Girl from '../girls.jpg'
import Down from '../donload.png'
import Base from '../components/base.apk';


const AdminContact = () => {
  return (

    <>
        <div class="container-fluid">
            <div class="hero-text-sec">
                <div class="hero-text">
                    <div class="contact-num-info">
                        <a href="tel:+918107628116" target="blank" class="number-info">+918107628116</a>
                    <div class="contact">
                        <a href="tel:+918107628116" target="blank" class=" contact-info">
                            <div class="whatsapp-num-img">
                                <img src={CallIcon} alt="phone"/>
                            </div><span>Call Now</span>
                        </a>
                        <a href="https://wa.me/918107628116" target="blank" class="whatsaap-info">
                            <div class="whatsapp-num-img">
                                <img src={Whaatsapp} alt="whatsapp"/>
                            </div><span>WhatsApp</span>
                        </a>
                    </div>
                </div>
                    <h1>Download Best Online <br/>Diamond Satta</h1>
                    <a href={Base} class="small-btn" >
                        <img src={Down} alt="download app"/>
                        Download App
                    </a>
                </div>
                <div class="hero-img-right">
                    <img src={Girl} alt="kalyan"/>
                </div>
            </div>
        </div>
    </>


  );
};

export default AdminContact;